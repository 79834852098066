import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { loadVkScheduledSecrets } from "../../actions/";
import {
  Loading,
  LoadingError,
  EmptyList,
  PageHeader,
} from "../../components/common/";
import { VkSecret } from "../../components/secrets/";
import _ from "lodash";
import { parseVkScheduledSecrets } from "../../actions/secrets/parse-vk-scheduled-secrets";
import {NavLink} from "react-router-dom";
import {baseUrl} from "../../libs";

class List extends Component {
  state = {
    vkSecrets: [],
    loading: true,
    loadingError: false,
  };

  componentDidMount() {
    this.props.load().then((e) => this.setState(e));
  }

  removeCallback = (vkSecret) => {
    const vkSecrets = [...this.state.vkSecrets];
    _.remove(vkSecrets, ["id", vkSecret.id]);
    this.setState({ vkSecrets });
  };

  handleParseClick = () => {
    this.props.parse().then((e) => {
      this.setState(e);
    });
  };

  render() {
    const { vkSecrets, loading, loadingError } = this.state;
    return (
      <div className="container mt-lg-5 mt-3 pb-lg-5 pb-3">
        <PageHeader/>

        <div className="secondary-nav moderator-actions mb-lg-4 mb-2">
          <nav>
            <NavLink
              exact
              to={`${baseUrl}/secrets/social_scheduled`}
              activeClassName="active"
              isActive={(match, location) =>
                location.pathname.startsWith(`${baseUrl}/secrets/social_scheduled`) &&
                !location.search.includes("vk")
              }
            >
              Telegram
            </NavLink>
            <NavLink
              exact
              to={`${baseUrl}/secrets/social_scheduled?vk`}
              activeClassName="active"
              isActive={(match, location) =>
                location.pathname.startsWith(`${baseUrl}/secrets/social_scheduled`) &&
                location.search.includes("vk")
              }
            >
              VK
            </NavLink>
          </nav>
        </div>

        <div className="row p-static">
          <div className="col-lg-8 col-12 order-lg-1">
            {loading && <Loading/>}
            {!loading && loadingError && <LoadingError/>}
            {!loading && !loadingError && vkSecrets.length === 0 && (
              <>
                <EmptyList/>

                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={this.handleParseClick}
                >
                  Спарсить
                </button>
              </>
            )}
            {!loading &&
              !loadingError &&
              vkSecrets.length > 0 &&
              vkSecrets.map((vkSecret, index) => (
                <VkSecret
                  key={vkSecret.id}
                  id={index + 1}
                  vkSecret={vkSecret}
                  removeCallback={this.removeCallback}
                />
              ))}
          </div>
        </div>
      </div>
    );
  }

  static propTypes = {
    load: PropTypes.func.isRequired,
    parse: PropTypes.func.isRequired,
  };
}

const mapDispatchToProps = (dispatch) => ({
  load: () => dispatch(loadVkScheduledSecrets()),
  parse: () => dispatch(parseVkScheduledSecrets()),
});

export const VkScheduledSecrets = connect(null, mapDispatchToProps)(List);
