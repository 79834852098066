import React, {useState} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { VKGeneralSettings, VKTimers, VKLinks } from "./";
import {NavLink} from "react-router-dom";
import {baseUrl} from "../../libs";

const VKSettingsByType = ({ vkTab, data, saveCallback }) => {
  return (
    <>
      <div className="secondary-nav moderator-actions mb-lg-4 mb-2">
        <nav>
          <NavLink
            exact
            to={`${baseUrl}/settings/telegram`}
            activeClassName="active"
            isActive={(m, l) => l.pathname.startsWith(`${baseUrl}/settings/telegram`)}
          >
            Telegram
          </NavLink>
          <NavLink
            exact
            to={`${baseUrl}/settings/vk`}
            activeClassName="active"
            isActive={(m, l) => l.pathname.startsWith(`${baseUrl}/settings/vk`)}
          >
            VK
          </NavLink>
        </nav>
      </div>
      {vkTab === "timers" && (
        <VKTimers data={data} saveCallback={saveCallback} />
      )}
      {vkTab === "links" && (
        <VKLinks data={data} saveCallback={saveCallback} />
      )}
      {vkTab !== "timers" && vkTab !== "links" && (
        <VKGeneralSettings data={data} saveCallback={saveCallback} />
      )}
    </>
  );
};

VKSettingsByType.propTypes = {
  vkTab: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  saveCallback: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  vkTab: state.filters.vk.vkTab,
});

export const  VKSettings = connect(mapStateToProps)(VKSettingsByType);
