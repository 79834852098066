import { DataLayer } from "../../libs/";

export const updateTelegramScheduledSecret = (telegramSecret) => async () => {
  const newState = {};

  await DataLayer.put({
    path: "/secrets/telegram_scheduled/" + telegramSecret.id + ".json",
    body: telegramSecret,
    loader: true,
  })
    .then((response) => {
      newState.telegramSecret = response;
      newState.showForm = false;
    })
    .catch((error) => {
      newState.telegramSecret = telegramSecret;
      newState.telegramSecret.errors = error.data
        ? error.data.errors || error.data
        : error.message;
    });

  return newState;
};
