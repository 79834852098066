import { DataLayer } from "../../libs/";

export const parseVkScheduledSecrets = () => async () => {
  const newState = {
    loading: false,
    loadingError: false,
  };

  await DataLayer.post({
    path: "/secrets/vk_scheduled/" + "parse.json",
    loader: true,
  })
    .then((response) => {
      newState.vkSecrets = response.vk_secrets;
    })
    .catch(() => {
      newState.loadingError = true;
    });

  return newState;
};
