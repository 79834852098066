import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {TelegramTimers} from "./telegram-timers";
import {TelegramGeneralSettings} from "./telegram-general";
import {NavLink} from "react-router-dom";
import {baseUrl} from "../../libs";

const TelegramSettingsByType = ({ telegramTab, data, saveCallback }) => {
  return (
    <>
      <div className="secondary-nav moderator-actions mb-lg-4 mb-2">
        <nav>
          <NavLink
            exact
            to={`${baseUrl}/settings/telegram`}
            activeClassName="active"
            isActive={(m, l) => l.pathname.startsWith(`${baseUrl}/settings/telegram`)}
          >
            Telegram
          </NavLink>
          <NavLink
            exact
            to={`${baseUrl}/settings/vk`}
            activeClassName="active"
            isActive={(m, l) => l.pathname.startsWith(`${baseUrl}/settings/vk`)}
          >
            VK
          </NavLink>
        </nav>
      </div>
      {telegramTab === "timers" && (
        <TelegramTimers data={data} saveCallback={saveCallback} />
      )}
      {telegramTab !== "timers" && telegramTab !== "links" && (
        <TelegramGeneralSettings data={data} saveCallback={saveCallback} />
      )}
    </>
  );
};

TelegramSettingsByType.propTypes = {
  telegramTab: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  saveCallback: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  telegramTab: state.filters.telegram.telegramTab,
});

export const TelegramSettings = connect(mapStateToProps)(
  TelegramSettingsByType
);
