import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import Settings from "./settings";
import { VKTimeForm, VKTimeItem } from "./";
import {TelegramTabs} from "./telegram-tabs";

export class TelegramTimers extends Settings {
  toggleForm = () => {
    this.setState({ showAddForm: !this.state.showAddForm });
  };

  saveTime = (timeObj) => {
    const data = { ...this.state.data };
    const timers = data.timers ? [...data.timers] : [];
    data.timers = this.updateTimers(timeObj, timers);
    this.setState({ data, showAddForm: false }, () => this.save("telegram"));
  };

  deleteTime = (id) => {
    const data = { ...this.state.data };
    data.timers = [...data.timers];
    _.remove(data.timers, ["id", id]);
    this.setState({ data }, () => this.save("telegram"));
  };

  updateTimers = (item, list) => {
    const indexById = _.findIndex(list, ["id", item.id]);
    const indexByTime = _.findIndex(list, ["time", item.time]);

    if (indexById >= 0) {
      list[indexById] = item;
    } else if (indexByTime >= 0) {
      alert("Ошибка! Уже существует таймер с таким временем.");
    } else {
      list.push(item);
    }

    const sortedList = list.sort((a, b) => {
      const [ah, am] = a.time.split(":").map((v) => parseInt(v, 10));
      const [bh, bm] = b.time.split(":").map((v) => parseInt(v, 10));
      const aVal = (ah < 8 ? ah + 24 : ah) * 100 + am;
      const bVal = (bh < 8 ? bh + 24 : bh) * 100 + bm;
      return aVal - bVal;
    });

    return sortedList.map((timeObj, id) => {
      return { ...timeObj, id };
    });
  };

  render() {
    const {
      showAddForm,
      data: { timers, links },
    } = this.state;
    return (
      <div className="settings-item bg-white p-2">
        <TelegramTabs
          addButtonTitle="время публикации"
          addButtonCallback={this.toggleForm}
        />

        <table className="table table-responsive table-striped table-sm table-hover">
          <colgroup>
            <col width="50" />
            <col width="90" />
            <col />
            <col width="90" />
          </colgroup>
          <thead>
            <tr>
              <th>№</th>
              <th>Время</th>
              <th>Ссылка</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {showAddForm && (
              <VKTimeForm
                key="add"
                links={links}
                close={this.toggleForm}
                saveCallback={this.saveTime}
              />
            )}
            {timers &&
              timers.map((item, index) => (
                <VKTimeItem
                  key={item.id}
                  id={index + 1}
                  item={item}
                  links={links}
                  saveCallback={this.saveTime}
                  deleteCallback={this.deleteTime}
                />
              ))}
          </tbody>
        </table>
      </div>
    );
  }

  static propTypes = {
    data: PropTypes.object.isRequired,
    saveCallback: PropTypes.func.isRequired,
  };
}
