const getParams = ({ pathname, search, hash }) => {
  const path = pathname.split("/");
  const params = new URLSearchParams(search);

  let type = "";
  let category = 0;
  let enableComments = "";
  let includeCategories = "";
  let excludeCategories = "";
  let country = 0;
  let period = "day";
  let searchQuery = "";
  let branch = null;
  let onlyChosen = false;
  let scheduledByMe = false;
  let hideHidden = false;
  let source = "";
  let secretLength = "";
  let recommendations = "";
  let rating = "";
  let weightedRating = "";
  let views = "";
  let uniqueness = "";
  let dateFrom = "";
  let dateTo = "";
  let removedDateFrom = "";
  let removedDateTo = "";
  let page = 1;
  let user = "";
  let action = null;
  let bannedOnly = false;
  let visibility = "";
  let hiddenBy = "";
  let sortBy = "";
  let sortOrder = null;
  let reasonType = null;
  let vkTab = null;
  let telegramTab = null;
  let removed = false;
  let excludePost = "";
  let authorFilter = "";
  let authorGroups = [];
  let usersDeletedFilter = "";
  let usersDeletedGroups = [];

  if (params) {
    const params = new URLSearchParams(search);
    category = parseInt(params.get("category_id") || 0, 10);
    enableComments = params.get("enable_comments") || "";
    includeCategories = params.get("include_categories") || "";
    excludeCategories = params.get("exclude_categories") || "";
    country = parseInt(params.get("country") || 0, 10);
    period = params.get("period") || "day";
    searchQuery = params.get("text") || "";
    branch = params.get("branch") || null;
    onlyChosen = !!params.get("only_chosen");
    scheduledByMe = !!params.get("scheduled_by_me");
    hideHidden = !!params.get("hide_hidden");
    source = params.get("source") || "";
    secretLength = params.get("secret_length") || "";
    recommendations = params.get("recommendations") || "";
    rating = params.get("rating") || "";
    weightedRating = params.get("weighted_rating") || "";
    views = params.get("views") || "";
    uniqueness = params.get("uniqueness") || "";
    dateFrom = params.get("date_from") || "";
    dateTo = params.get("date_to") || "";
    removedDateFrom = params.get("removed_date_from") || "";
    removedDateTo = params.get("removed_date_to") || "";
    page = parseInt(params.get("page") || 1, 10);
    user = parseInt(params.get("user"), 10) || "";
    bannedOnly = !!params.get("banned_only");
    visibility = params.get("visibility") || "";
    hiddenBy = params.get("hidden_by") || "";
    sortBy = params.get("sort_by") || "";
    sortOrder = params.get("sort_order") || null;
    excludePost = parseInt(params.get("exclude_post"), 10) || "";
    authorFilter = params.get("author_filter") || "";
    authorGroups = params.get("author_groups")
      ? params.get("author_groups").split(",")
      : [];
    usersDeletedFilter = params.get("users_deleted_filter") || "";
    usersDeletedGroups = params.get("users_deleted_groups")
      ? params.get("users_deleted_groups").split(",")
      : [];
  }

  if (path[2] === "posts") {
    if (path[3] && path[3].match(/^\d+$/)) {
      type = path[5] || "all";
    } else {
      type = path[3] || "published";
    }
  } else if (path[2] === "comments") {
    type = path[3] || "all";
    if (type === "actions") {
      action = params && params.has("ban") ? "ban" : "hide";
    }
  } else if (path[2] === "users") {
    if (!path[4]) {
      type = path[3] || "";
    } else if (["rating_awards", "history"].includes(path[4])) {
      type = path[5] || "";
    } else if (path[4] === "actions") {
      type = path[5] || "posts";
    } else if (path[4] === "secrets") {
      type = path[5];
    }

    if (path[4] === "posts" && path[5] === "removed") {
      removed = true;
    }
  } else if (path[2] === "publishers") {
    type = path[5];
    period = path[6];
  } else if (path[2] === "settings" && path[3] === "reasons") {
    reasonType = hash ? hash.replace("#", "") : "for_ban";
  } else if (path[2] === "settings" && path[3] === "vk") {
    vkTab = hash ? hash.replace("#", "") : "general";
  } else if (path[2] === "settings" && path[3] === "telegram") {
    telegramTab = hash ? hash.replace("#", "") : "general";
  } else {
    // secrets, chat_message_complains, social_accounts, statistics
    type = path[3] || "";
  }

  return {
    // Параметры, использующиеся в общих компонентах.
    // Дублируются внутри фильтров контейнеров,
    // чтобы была возможность отловить изменение фильтров контейнера
    category, // component CategoryDropdown
    includeCategories, // component CategoryDropdown
    excludeCategories, // component CategoryDropdown
    page, // component Pagination
    searchQuery, // component SearchBox
    sortBy, // component SortingLinks
    sortOrder, // component SortingLinks

    posts: {
      category,
      enableComments,
      searchQuery,
      type,
      period,
      removed,
    },

    secrets: {
      category,
      includeCategories,
      excludeCategories,
      page,
      searchQuery,
      type,
      onlyChosen,
      scheduledByMe,
      source,
      secretLength,
      recommendations,
      rating,
      weightedRating,
      views,
      uniqueness,
      dateFrom,
      dateTo,
      removedDateFrom,
      removedDateTo,
      period,
      sortBy,
      authorFilter,
      authorGroups,
      usersDeletedFilter,
      usersDeletedGroups,
    },

    comments: {
      category,
      includeCategories,
      excludeCategories,
      type,
      user,
      action,
      excludePost,
    },

    post_comments: {
      type,
      branch,
      hideHidden,
    },

    user_comments: {
      searchQuery,
      visibility,
      hiddenBy,
    },

    chat_message_complains: {
      page,
      type,
    },

    users: {
      page,
      searchQuery,
      type,
      sortBy,
      sortOrder,
    },

    publishers: {
      type,
      period,
      category,
      includeCategories,
      excludeCategories,
      sortBy: sortBy || "scheduled_to",
      page,
    },

    social_accounts: {
      searchQuery,
      user,
      type,
      bannedOnly,
    },

    ratingAwards: {
      page,
      type,
    },

    actions: {
      page,
      type,
    },

    history: {
      page,
      type,
    },

    statistics: {
      type,
    },

    cities: {
      searchQuery,
      country,
    },

    reasons: {
      reasonType,
    },

    vk: {
      vkTab,
    },

    telegram: {
      telegramTab,
    },
  };
};

const reducer = (state = {}, action) => {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE": {
      return getParams(action.payload.location);
    }

    case "SET_USER": {
      const { pathname, search, hash } = window.location;
      return getParams({ pathname, search, hash });
    }

    case "LOG_OUT":
      return {};

    default:
      return state;
  }
};

export default reducer;
