import { DataLayer } from "../../libs/";

export const deleteTelegramScheduledSecret = (telegramSecret) => async () => {
  await DataLayer.delete({
    path: "/secrets/telegram_scheduled/" + telegramSecret.id + ".json",
    loader: true,
  }).catch((error) => {
    console.log(error.data ? error.data.errors || error.data : error.message);
  });

  return telegramSecret;
};
