import React from "react";
import PropTypes from "prop-types";

import { VKTabs } from "./";
import Settings from "./settings";
import { baseUrl, fullDate } from "../../libs";
import { BtnDropdown } from "../common";
import { SvgDots } from "../../svg";
import { Link } from "react-router-dom";

export class VKGeneralSettings extends Settings {
  render() {
    const { data: vk } = this.state;
    return (
      <div className="settings-item bg-white p-2">
        <VKTabs />

        <div className="row align-items-center mt-3">
          <div className="col">Автопостинг</div>
          <div className="col-sm-5 col-6">
            {this.booleanRadio("auto_posting_is_on", ["Включен", "Отключен"])}
          </div>
        </div>

        <div className="row align-items-center mt-3">
          <div className="col">ID приложения</div>
          <div className="col-sm-5 col-6">
            <input
              type="text"
              className="form-control"
              name="client_id"
              value={vk.client_id || ""}
              disabled={true}
            />
          </div>
        </div>

        <div className="row align-items-center mt-3">
          <div className="col">ID сообщества</div>
          <div className="col-sm-5 col-6">{this.input("group_id")}</div>
        </div>

        <div className="row align-items-center mt-3">
          <div className="col">ID картинки-сепаратора</div>
          <div className="col-sm-5 col-6">{this.input("image_id")}</div>
        </div>

        <div className="row align-items-center mt-3">
          <div className="col">
            Токен (
            <a
              href={`http://oauth.vk.com/authorize?client_id=${vk.client_id}&scope=wall,offline&response_type=code&redirect_uri=https://api.vk.com/blank.html`}
              target="_blank"
              rel="noreferrer"
            >
              получить новый
            </a>
            )
          </div>
          <div className="col-sm-5 col-6">
            {vk.access_token_updated_at && (
              <p>
                Дата последнего обновления:{" "}
                {fullDate(vk.access_token_updated_at)}
              </p>
            )}
          </div>
        </div>

        <div className="row align-items-center">
          <div className="col">
            <small>Для обновления токена:</small>
            <br />
            <small>- перейдите по ссылке "получить новый";</small>
            <br />
            <small>
              - авторизуйтесь во ВК и дайте разрешение приложению Подслушано;
            </small>
            <br />
            <small>
              - скопируйте значение переменной `code` из адресной строки в поле
              справа;
            </small>
            <br />
            <small>- сохраните изменения.</small>
            <br />
          </div>
          <div className="col-sm-5 col-6">
            <input
              type="text"
              className="form-control"
              placeholder="Значение `code` из адресной строки"
              name="code"
              value={vk.code || ""}
              onChange={this.handleInputChange}
            />
          </div>
        </div>

        <div className="row align-items-center mt-3">
          <div className="col">Источник постов</div>
          <div className="col-sm-5 col-6">
            <select
              className="form-control custom-form"
              style={{
                padding: "4px 8px",
              }}
              name="source"
              value={vk.source || "scheduled"}
              onChange={this.handleInputChange}
            >
              <option value="best_of_day">Лучшие за вчера</option>
              <option value="scheduled">Запланированные на завтра</option>
            </select>
          </div>
        </div>

        <div className="row align-items-center mt-3">
          <div className="col">
            Замена категорий
            <br />
            <small>
              <u>Пример:</u>
            </small>
            <br />
            <small>Великая Победа,#Подслушано_Великая_победа@overhear</small>
            <br />
            <small>Любовь,#Подслушано_любовь@overhear &lt;3</small>
            <br />
          </div>
          <div className="col-sm-5 col-6">
            {this.textarea("category_replace_map")}
          </div>
        </div>

        <div className="row align-items-center mt-3">
          <div className="col">
            Игнорируемые категории
            <br />
            <small>
              <u>Пример:</u>
            </small>
            <br />
            <small>Разное,Смешное,Дорога Мой Дом</small>
          </div>
          <div className="col-sm-5 col-6">
            {this.textarea("ignored_categories")}
          </div>
        </div>

        <div className="row align-items-center mt-3">
          <div className="col">Ссылки</div>
          <div className="col-sm-5 col-6">
            {this.booleanRadio("show_links", ["Включены", "Отключены"])}
          </div>
        </div>

        <div className="mt-7 text-center">
          <button className="btn btn-primary" onClick={() => this.save("vk")}>
            Сохранить
          </button>
        </div>
      </div>
    );
  }

  static propTypes = {
    data: PropTypes.object.isRequired,
    saveCallback: PropTypes.func.isRequired,
  };
}
