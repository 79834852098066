import React, { Component } from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";

import {
  loadSettings,
  loadReasons,
  loadCategories,
  loadCountries,
  updateSettings,
} from "../../actions/";
import { PageHeader, Loading, LoadingError } from "../../components/common/";
import { baseUrl } from "../../libs/";

import {
  AdSettings,
  Reasons,
  CategorySettings,
  CountrySettings,
  CitySettings,
  CommentSettings,
  GeneralSettings,
  SystemSettings,
  UndersideSettings,
  VKSettings,
} from "../../components/settings/";
import {TelegramSettings} from "../../components/settings/telegram";

class Settings extends Component {
  state = {
    loading: true,
    loadingError: false,
    settings: {},
    reasons: {},
  };

  componentDidMount() {
    this.props.loadSettings().then((e) => this.setState(e));
    this.props.loadReasons().then((e) => this.setState(e));
    this.props.loadCategoriesWithPostCount();
    this.props.forceLoadCountries();
  }

  updateSettingsState = (newSettings) => {
    this.props
      .updateSettings(newSettings)
      .then((response) => this.setState({ settings: response }))
      .catch((e) => {
        alert(e.data.error + ": " + e.data.message);
      });
  };

  updateReasonsState = (newReasons) => {
    this.setState({ reasons: newReasons });
  };

  render() {
    const { settings, reasons, loading, loadingError } = this.state;
    const { categories, countries } = this.props;
    const commentSettings = { ...settings.comments };
    commentSettings.bad_feed_words = settings.bad_feed_words;
    commentSettings.comment_block_words = settings.comment_block_words;
    commentSettings.complain_feed_words = settings.complain_feed_words;

    return (
      <div className="container mt-lg-5 mt-3 pb-lg-5 pb-3">
        <PageHeader />

        {loading && <Loading />}
        {loadingError && <LoadingError />}
        {!loading && !loadingError && (
          <Switch>
            <Route
              exact
              path={`${baseUrl}/settings/general`}
              render={() => (
                <GeneralSettings
                  data={settings.general}
                  saveCallback={this.updateSettingsState}
                />
              )}
            />
            <Route
              exact
              path={`${baseUrl}/settings/comments`}
              render={() => (
                <CommentSettings
                  data={commentSettings}
                  saveCallback={this.updateSettingsState}
                />
              )}
            />
            <Route
              exact
              path={`${baseUrl}/settings/categories`}
              render={() => <CategorySettings data={categories} />}
            />
            <Route
              exact
              path={`${baseUrl}/settings/countries`}
              render={() => <CountrySettings data={countries} />}
            />
            <Route
              exact
              path={`${baseUrl}/settings/cities`}
              render={() => <CitySettings />}
            />
            <Route
              exact
              path={`${baseUrl}/settings/reasons`}
              render={() => (
                <Reasons
                  data={reasons}
                  saveCallback={this.updateReasonsState}
                />
              )}
            />
            <Route
              exact
              path={`${baseUrl}/settings/ad`}
              render={() => (
                <AdSettings
                  data={settings.ad}
                  saveCallback={this.updateSettingsState}
                />
              )}
            />
            <Route
              exact
              path={`${baseUrl}/settings/underside`}
              render={() => (
                <UndersideSettings
                  data={settings.underside}
                  saveCallback={this.updateSettingsState}
                />
              )}
            />
            <Route
              exact
              path={`${baseUrl}/settings/vk`}
              render={() => (
                <VKSettings
                  data={settings.vk}
                  saveCallback={this.updateSettingsState}
                />
              )}
            />
            <Route
              exact
              path={`${baseUrl}/settings/telegram`}
              render={() => (
                <TelegramSettings
                  data={settings.telegram}
                  saveCallback={this.updateSettingsState}
                />
              )}
            />
            <Route
              exact
              path={`${baseUrl}/settings/system`}
              render={() => (
                <SystemSettings
                  data={settings.system}
                  saveCallback={this.updateSettingsState}
                />
              )}
            />
          </Switch>
        )}
      </div>
    );
  }

  static propTypes = {
    categories: PropTypes.arrayOf(PropTypes.object).isRequired,
    countries: PropTypes.arrayOf(PropTypes.object).isRequired,
    loadSettings: PropTypes.func.isRequired,
    loadReasons: PropTypes.func.isRequired,
    loadCategoriesWithPostCount: PropTypes.func.isRequired,
    forceLoadCountries: PropTypes.func.isRequired,
    updateSettings: PropTypes.func.isRequired,
  };
}

const mapStateToProps = (state) => ({
  categories: state.categoriesWithPostCount,
  countries: state.countries,
});

const mapDispatchToProps = (dispatch) => ({
  loadSettings: () => dispatch(loadSettings()),
  loadReasons: () => dispatch(loadReasons()),
  loadCategoriesWithPostCount: () =>
    dispatch(loadCategories({ withPostCount: true })),
  forceLoadCountries: () => dispatch(loadCountries(true)),
  updateSettings: (data) => dispatch(updateSettings(data)),
});

export const SettingsShow = connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings);
