import { DataLayer } from "../../libs/";

export const parseTelegramScheduledSecrets = () => async () => {
  const newState = {
    loading: false,
    loadingError: false,
  };

  await DataLayer.post({
    path: "/secrets/telegram_scheduled/" + "parse.json",
    loader: true,
  })
    .then((response) => {
      newState.telegramSecrets = response.telegram_secrets;
    })
    .catch(() => {
      newState.loadingError = true;
    });

  return newState;
};
