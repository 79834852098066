import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  Loading,
  LoadingError,
  EmptyList,
  PageHeader,
} from "../../components/common/";
import _ from "lodash";
import {NavLink} from "react-router-dom";
import {baseUrl} from "../../libs";
import {loadTelegramScheduledSecrets} from "../../actions/secrets/load-telegram-scheduled-secrets";
import {TelegramSecret, telegramSecret} from "../../components/secrets/telegram-secret";
import {parseVkScheduledSecrets} from "../../actions/secrets/parse-vk-scheduled-secrets";
import {parseTelegramScheduledSecrets} from "../../actions/secrets/parse-telegram-scheduled-secrets";

class List extends Component {
  state = {
    telegramSecrets: [],
    loading: true,
    loadingError: false,
  };

  componentDidMount() {
    this.props.load().then((e) => this.setState(e));
  }

  removeCallback = (telegramSecret) => {
    const telegramSecrets = [...this.state.telegramSecrets];
    _.remove(telegramSecrets, ["id", telegramSecret.id]);
    this.setState({ telegramSecrets });
  };

  handleParseClick = () => {
    this.props.parse().then((e) => {
      this.setState(e);
    });
  };


  render() {
    const { telegramSecrets, loading, loadingError } = this.state;
    return (
      <div className="container mt-lg-5 mt-3 pb-lg-5 pb-3">
        <PageHeader/>

        <div className="secondary-nav moderator-actions mb-lg-4 mb-2">
          <nav>
            <NavLink
              exact
              to={`${baseUrl}/secrets/social_scheduled`}
              activeClassName="active"
              isActive={(match, location) =>
                location.pathname.startsWith(`${baseUrl}/secrets/social_scheduled`) &&
                !location.search.includes("vk")
              }
            >
              Telegram
            </NavLink>
            <NavLink
              exact
              to={`${baseUrl}/secrets/social_scheduled?vk`}
              activeClassName="active"
              isActive={(match, location) =>
                location.pathname.startsWith(`${baseUrl}/secrets/social_scheduled`) &&
                location.search.includes("vk")
              }
            >
              VK
            </NavLink>
          </nav>
        </div>

        <div className="row p-static">
          <div className="col-lg-8 col-12 order-lg-1">
            {loading && <Loading/>}
            {!loading && loadingError && <LoadingError/>}
            {!loading && !loadingError && telegramSecrets.length === 0 && (
              <>
                <EmptyList/>

                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={this.handleParseClick}
                >
                  Спарсить
                </button>
              </>
            )}
            {!loading &&
              !loadingError &&
              telegramSecrets.length > 0 &&
              telegramSecrets.map((telegramSecret, index) => (
                <TelegramSecret
                  key={telegramSecret.id}
                  id={index + 1}
                  telegramSecret={telegramSecret}
                  removeCallback={this.removeCallback}
                />
              ))}
          </div>
        </div>
      </div>
    );
  }

  static propTypes = {
    load: PropTypes.func.isRequired,
    parse: PropTypes.func.isRequired,
  };
}

const mapDispatchToProps = (dispatch) => ({
  load: () => dispatch(loadTelegramScheduledSecrets()),
  parse: () => dispatch(parseTelegramScheduledSecrets()),
});

export const TelegramScheduledSecrets = connect(null, mapDispatchToProps)(List);
