import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import _ from "lodash";


import { CategoryDropdown, Modal, Textarea } from "../common/";
import { SvgEdit, SvgRemove } from "../../svg/";
import {
  baseUrl,
  fullDate,
  pluralizeCharacters,
  textAutoCorrection,
} from "../../libs/";
import moment from "moment";
import {publishTelegramScheduledSecret} from "../../actions/secrets/publish-telegram-scheduled-secret";
import {updateTelegramScheduledSecret} from "../../actions/secrets/update-telegram-scheduled-secret";
import {deleteTelegramScheduledSecret} from "../../actions/secrets/delete-telegram-scheduled-secret";

class SecretComponent extends Component {
  state = {
    telegramSecret: this.props.telegramSecret,
    note: this.props.telegramSecret.note,
    scheduledTo: this.props.telegramSecret.scheduled_to,
    cursor: null,
    showForm: false,
    showPublishConfirmation: false,
    showDeleteConfirmation: false,
  };

  handleToggleForm = () => {
    const { showForm, telegramSecret } = this.state;
    this.setState({
      showForm: !showForm,
      note: telegramSecret.note,
      scheduledTo: telegramSecret.scheduled_to,
    });
  };

  handleBackspace = (e) => {
    if (
      e.key !== "Backspace" ||
      e.target.selectionStart !== e.target.selectionEnd
    ) {
      return;
    }
    e.preventDefault(e);

    const { text, cursor } = textAutoCorrection(e, { reverse: true });
    this.setState({ note: text, cursor });
  };

  handleNoteChange = (e) => {
    const { text, cursor } = textAutoCorrection(e);
    this.setState({ note: text, cursor });
  };

  handleScheduledToChange = (dateTime) => {
    this.setState({
      scheduledTo: moment(dateTime).format("YYYY-MM-DDTHH:mm:ss"),
    });
  };

  togglePublishConfirmation = () => {
    const { showPublishConfirmation, telegramSecret } = this.state;

    if (telegramSecret.telegram_url) {
      this.props.removeCallback(telegramSecret);
    } else if (!showPublishConfirmation) {
      telegramSecret.error = null;
      this.setState({ showPublishConfirmation: true, telegramSecret });
    } else {
      this.setState({ showPublishConfirmation: false });
    }
  };

  toggleDeleteConfirmation = () => {
    this.setState({
      showDeleteConfirmation: !this.state.showDeleteConfirmation,
    });
  };

  updateCategory = (category) => {
    const telegramSecret = { ...this.state.telegramSecret };
    telegramSecret.category_id = category;
    this.props.update(telegramSecret).then((e) => this.setState(e));
  };

  publish = () => {
    this.props.publish(this.state.telegramSecret).then((e) => this.setState(e));
  };

  update = () => {
    const { telegramSecret, note, scheduledTo } = this.state;
    const { telegramSettings } = this.props;
    const time = scheduledTo.match(
      /^(\d{4}-\d{2}-\d{2}T)(\d{2}:\d{2})(.*)$/
    )[2];
    const timer = _.find(telegramSettings.timers, ["time", time]);
    const link = timer ? _.find(telegramSettings.links, ["id", timer.link_id]) : null;
    telegramSecret.link_id = link ? link.id : null;
    this.props
      .update({ ...telegramSecret, note, scheduled_to: scheduledTo })
      .then((e) => this.setState(e));
  };

  delete = () => {
    this.props.delete(this.state.telegramSecret).then(this.props.removeCallback);
  };

  render() {
    const {
      telegramSecret,
      note,
      scheduledTo,
      cursor,
      showForm,
      showPublishConfirmation,
      showDeleteConfirmation,
    } = this.state;

    const { id, telegramSettings } = this.props;

    const link = telegramSecret.link_id
      ? _.find(telegramSettings.links, ["id", telegramSecret.link_id]).url
      : null;

    return (
      <div className="row no-gutters mb-3 secret-item-wrapper">
        <div className="col">
          <div
            className={`secret-item${
              telegramSecret.manually_updated ? " edited" : ""
            }`}
          >
            <header className="d-flex w-100 align-items-center">
              <span className={`btn btn-sm rounded mr-2 btn-vk-id cursor`}>
                #{id}
              </span>
              <div>
                <CategoryDropdown
                  selectedId={telegramSecret.category_id}
                  onChange={this.updateCategory}
                />
                {telegramSecret.tag && (
                  <div className="text-muted">{telegramSecret.tag}</div>
                )}
              </div>
              <span className="text-muted ml-auto">
                {showForm && [
                  <em key="edit_text">Редактирование</em>,
                  <span key="split" className="mx-1">
                    |
                  </span>,
                ]}
                {pluralizeCharacters(
                  showForm ? note.length : telegramSecret.note.length
                )}
              </span>
            </header>

            {showForm ? (
              <div className="form">
                <Textarea
                  onKeyDown={this.handleBackspace}
                  onChange={this.handleNoteChange}
                  cursor={cursor}
                  name="note"
                  value={note}
                />

                <h6 className="my-2">Время публикации</h6>
                <DatePicker
                  selected={moment(scheduledTo || "").toDate()}
                  minDate={moment().toDate()}
                  onChange={this.handleScheduledToChange}
                  dateFormat="DD.MM.YYYY HH:mm:ss"
                  timeFormat="HH:mm"
                  timeIntervals="5"
                  showTimeSelect={true}
                  value={
                    scheduledTo
                      ? moment(scheduledTo).format("DD.MM.YYYY HH:mm:ss")
                      : "Сейчас"
                  }
                  className="form-control custom-select"
                />

                <div className="text-right mt-3">
                  <button
                    className="btn btn-secondary mr-2"
                    onClick={this.handleToggleForm}
                  >
                    Отмена
                  </button>
                  <button className="btn btn-primary" onClick={this.update}>
                    Сохранить
                  </button>
                </div>
              </div>
            ) : (
              <div className="std">{telegramSecret.note}</div>
            )}

            <div className="details text-muted">
              <div className="row">
                <div className="col-md-4 col-6 mt-2">
                  <b>Дата публикации:</b>
                  {fullDate(telegramSecret.scheduled_to)}
                </div>
                <div className="col-md-2 col-6 mt-2">
                  <b>Оригинал:</b>
                  <Link
                    to={
                      telegramSecret.candidate_post_id
                        ? `${baseUrl}/secrets/${telegramSecret.candidate_post_id}`
                        : `${baseUrl}/posts/${telegramSecret.post_id}`
                    }
                    target="_blank"
                  >
                    {`#${telegramSecret.candidate_post_id || telegramSecret.post_id}`}
                  </Link>
                </div>
                {link && (
                  <div className="col-md-6 col-12 mt-2">
                    <b>Ссылка:</b>
                    <Link
                      to={link}
                      target="_blank"
                      title={link}
                      style={{ wordBreak: "break-all" }}
                    >
                      {link.slice(0, 40)}
                    </Link>
                  </div>
                )}
              </div>
            </div>

            <footer className="text-right secret-footer-buttons mt-3">
              <button
                className="btn btn-vk-publish"
                onClick={this.togglePublishConfirmation}
              >
                Опубликовать Telegram
              </button>
            </footer>
          </div>
        </div>

        {/* side buttons */}
        <MediaQuery minWidth={576}>
          {(matches) => (
            <div className="col-sm-auto col-12 ml-sm-15 secret-side-buttons">
              <button
                title="Удалить"
                type="button"
                className={`btn ${
                  matches ? "bg-white lh-0" : "danger-light"
                } mb-1`}
                onClick={this.toggleDeleteConfirmation}
              >
                {matches ? (
                  <SvgRemove width={16} height={16} className="fill-red" />
                ) : (
                  "Удалить"
                )}
              </button>

              <button
                title="Редактировать"
                type="button"
                className={`btn ${
                  matches ? "bg-white lh-0" : "primary-light"
                } mb-1`}
                onClick={this.handleToggleForm}
              >
                {matches ? (
                  <SvgEdit width={16} height={16} className="fill-primary" />
                ) : (
                  "Редактировать"
                )}
              </button>
            </div>
          )}
        </MediaQuery>

        {showPublishConfirmation && (
          <Modal
            key="confirm"
            title={`Публикация секрета #${id}`}
            toggleModal={this.togglePublishConfirmation}
            small
          >
            <Fragment>
              {!telegramSecret.telegram_url && !telegramSecret.error && (
                <div>
                  <div>
                    Вы действительно хотите сейчас опубликовать секрет в Telegram?
                  </div>
                  <div className="mt-25 text-right">
                    <button
                      type="button"
                      onClick={this.togglePublishConfirmation}
                      className="btn btn-primary mr-15"
                    >
                      Отмена
                    </button>
                    <button
                      type="button"
                      onClick={this.publish}
                      className="btn btn-secondary"
                    >
                      Опубликовать
                    </button>
                  </div>
                </div>
              )}

              {!telegramSecret.telegram_url && telegramSecret.error && (
                <div>
                  <div>
                    При публикации секрета в Telegram произошла ошибка:
                    <div className="alert alert-danger mt-1">
                      {telegramSecret.error}
                    </div>
                  </div>
                  <div className="mt-25 text-right">
                    <button
                      type="button"
                      onClick={this.togglePublishConfirmation}
                      className="btn btn-secondary"
                    >
                      Ок
                    </button>
                  </div>
                </div>
              )}

              {telegramSecret.telegram_url && (
                <div>
                  <div>
                    Секрет успешно опубликован в Telegram.
                    <br />
                    URL:{" "}
                    <a href={telegramSecret.telegram_url} target="_blank" rel="noreferrer">
                      {telegramSecret.telegram_url}
                    </a>
                  </div>
                  <div className="mt-25 text-right">
                    <button
                      type="button"
                      onClick={this.togglePublishConfirmation}
                      className="btn btn-secondary"
                    >
                      Ок
                    </button>
                  </div>
                </div>
              )}
            </Fragment>
          </Modal>
        )}

        {showDeleteConfirmation && (
          <Modal
            key="confirm"
            title={`Удаление секрета #${id}`}
            toggleModal={this.toggleDeleteConfirmation}
            small
          >
            <div>
              <div>
                Вы действительно хотите удалить секрет из автопостинга Telegram?
              </div>
              <div className="mt-25 text-right">
                <button
                  type="button"
                  onClick={this.toggleDeleteConfirmation}
                  className="btn btn-primary mr-15"
                >
                  Отмена
                </button>
                <button
                  type="button"
                  onClick={this.delete}
                  className="btn btn-secondary"
                >
                  Удалить
                </button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }

  static propTypes = {
    id: PropTypes.number.isRequired,
    telegramSecret: PropTypes.object.isRequired,
    removeCallback: PropTypes.func.isRequired,
    telegramSettings: PropTypes.object.isRequired,
    publish: PropTypes.func,
    update: PropTypes.func,
    delete: PropTypes.func,
  };
}

const mapStateToProps = (state) => ({
  telegramSettings: state.telegramSettings,
});

const mapDispatchToProps = (dispatch) => ({
  publish: (data) => dispatch(publishTelegramScheduledSecret(data)),
  update: (data) => dispatch(updateTelegramScheduledSecret(data)),
  delete: (data) => dispatch(deleteTelegramScheduledSecret(data)),
});

export const TelegramSecret = connect(
  mapStateToProps,
  mapDispatchToProps
)(SecretComponent);
