import { DataLayer } from "../../libs/";

export const publishTelegramScheduledSecret = (telegramSecret) => async () => {
  const newState = {};

  await DataLayer.post({
    path: "/secrets/telegram_scheduled/" + telegramSecret.id + "/publish.json",
    loader: true,
  })
    .then((response) => {
      newState.telegramSecret = response;
    })
    .catch((error) => {
      newState.showPublishConfirmation = false;
      newState.errors = error.data
        ? error.data.errors || error.data
        : error.message;
    });

  return newState;
};
