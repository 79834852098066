import React from "react";
import PropTypes from "prop-types";

import Settings from "./settings";
import { fullDate } from "../../libs";
import {TelegramTabs} from "./telegram-tabs";

export class TelegramGeneralSettings extends Settings {
  render() {
    const { data: telegram } = this.state;
    return (
      <div className="settings-item bg-white p-2">
        <TelegramTabs />

        <div className="row align-items-center mt-3">
          <div className="col">Автопостинг</div>
          <div className="col-sm-5 col-6">
            {this.booleanRadio("auto_posting_is_on", ["Включен", "Отключен"])}
          </div>
        </div>

        <div className="row align-items-center mt-3">
          <div className="col">ID канала</div>
          <div className="col-sm-5 col-6">{this.input("channel_id")}</div>
        </div>

        <div className="row align-items-center mt-3">
          <div className="col">Источник постов</div>
          <div className="col-sm-5 col-6">
            <select
              className="form-control"
              name="source"
              value={telegram.source || "Лучшие за вчера"}
              onChange={this.handleInputChange}
            >
              <option value="best_of_day">Лучшие за вчера</option>
              <option value="sheduled">Запланированные на завтра</option>
            </select>
          </div>
        </div>

        <div className="row align-items-center mt-3">
          <div className="col">
            Замена категорий
            <br />
            <small>
              <u>Пример:</u>
            </small>
            <br />
            <small>Великая Победа,#Подслушано_Великая_победа@overhear</small>
            <br />
            <small>Любовь,#Подслушано_любовь@overhear &lt;3</small>
            <br />
          </div>
          <div className="col-sm-5 col-6">
            {this.textarea("category_replace_map")}
          </div>
        </div>

        <div className="row align-items-center mt-3">
          <div className="col">
            Игнорируемые категории
            <br />
            <small>
              <u>Пример:</u>
            </small>
            <br />
            <small>Разное,Смешное,Дорога Мой Дом</small>
          </div>
          <div className="col-sm-5 col-6">
            {this.textarea("ignored_categories")}
          </div>
        </div>

        <div className="mt-7 text-center">
          <button className="btn btn-primary" onClick={() => this.save("telegram")}>
            Сохранить
          </button>
        </div>
      </div>
    );
  }

  static propTypes = {
    data: PropTypes.object.isRequired,
    saveCallback: PropTypes.func.isRequired,
  };
}
